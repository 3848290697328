import React from 'react'

import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Component = ({ locale }) => {
  let content = {
    en: '404: not found',
    zh: '404: 没有找到页面',
  }
  content = content[locale]

  const page = {
    title: content,
  }

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <div style={{ height: '150px', backgroundColor: '#8b0304' }} />
        <div>
          <div className='wrapper pad-sides'>
            <div className='inner'>
              <div className='wrapper pad-sides'>
                <IntroText content={<h1>{content}</h1>} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Component
