import React from 'react'

import NotFound from 'components/404'

const Page = () => {
  const locale = process.env.GATSBY_LOCALE
  return <NotFound locale={locale} />
}

export default Page
